const common = {
  /* We typically want to put everything we don't
  messing with the default theme under the brand-key */
  //   media: 'EvokedSet media styles',
  media: {
    tiny: 350,
    xsmall: 786,
    small: 900,
    medium: 1200,
    large: 1800,
    huge: 2400,
  },
  card: {
    background: 'white',
    color: 'black',
    radius: '5px',
  },
  confettiColors: ['#19aaf8', '#0c1026', '#a3deff', '#fff'],
  strings: {
    startBtnLabel: 'Start Quiz',
    restartBtnLabel: 'Start på nytt',
    proceedBtnLabel: 'Videre',
    submitBtnLabel: 'Send inn',
    submittingBtnLabel: 'Sender...',
    submitErrorMessage: 'Ooops! En feil oppstod, skjemaet ble ikke sendt inn.',
    checkboxLabel: 'Jeg vil gjerne motta e-post om konkurranser',
    inactiveQuizLabel: 'Denne quizzen er nå avsluttet.',
  },
};

const evokedsetBasic = {
  ...common,
  fonts: {
    light: 'sans-serif',
    default: 'Poppins',
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: '#777',
    color: '#333',
    questionColor: '#fff',
    linkColor: 'tomato',
    primary: {
      default: 'tomato',
      darker: 'crimson',
      lighter: 'hotpink',
    },
    win: {
      default: '#28a845',
    },
    fail: {
      default: '#FF648C',
    },
  },
  buttons: {
    radius: '3px',
    hover: 'tomato',
    font: 'Poppins',
  },
  strings: {
    ...common.strings,
  },
};

// TODO: Dry config: We need a way to refer to values within the same config

const tn = {
  fontLight: 'telenorlight',
  fontRegular: 'telenorregular',
  yngFontLight: 'gtpressuralight',
  yngFontRegular: '',

  // fontBiggest: '2.5em',
  fontBig: '2em',
  fontStandard: '1.2em',

  // YNG cascade
  yngFontSizeLarge: '30px',
  yngFontSizeMedium: '24px',
  yngFontSizeSmall: '18px',

  blueMain: '#19aaf8',
  blueDark: '#0078cf',
  colorCorrect: '#28a845',
  colorIncorrect: '#FF648C',
  colorCorrectYng: '#D1E5DB',
  colorIncorrectYng: '#FDEEF1',
  borderRadius: '3px',
};

const telenorLight = {
  ...common,

  fonts: {
    light: 'telenorlight',
    default: 'telenorregular',
    copy: 'telenorlight',
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: '#fff',
    color: '#000',
    questionColor: '#fff',
    linkColor: '#19aaf8',
    primary: {
      default: '#19aaf8',
      darker: '#0078cf',
      lighter: '#19aaf8',
    },
    win: {
      default: '#28a845',
    },
    fail: {
      default: '#FF648C',
    },
  },
  card: {
    background: 'white',
    color: 'black',
    radius: '0 0 2em 0',
  },
  header: {
    mobileMenuFont: tn.fontLight,
    mobileNavDividerColor: tn.blueMain,
    mobileMenuFontColor: tn.blueMain,
  },
  frontPage: {
    buttonColor: tn.blueMain,
    buttonColorHover: tn.blueDark,
  },
  pagesStyles: {
    fontSizeHeading: '2.5em',
    fontSizeCopy: '18px',
    fontSizeButton: '1.2em',
    fontHeading: tn.fontLight,
    fontCopy: tn.fontLight,
    fontButton: tn.fontRegular,
  },
  buttons: {
    radius: '3px',
    hover: '#19aaf8',
    font: 'telenorregular',
    fontSize: '1.2em',
    padding: '0.8em 1.7em 1em',
    weight: 'normal',
  },
  questionStyle: {
    fontQuestion: tn.fontRegular,
    fontAnswer: tn.fontRegular,
    fontColor: 'red',
    buttonTextColor: 'white',
    buttonColor: tn.blueMain,
    buttonColorHover: tn.blueDark,
    fontSizeQuestion: '1.2em',
    fontSizeAnswer: '1.2em',
    answerButtonRadius: '3px',
    answerTextColor: 'white',
    answerTextWeight: 400,
  },
  feedbackStyle: {
    correctColor: tn.green,
    correctColorHover: tn.green,
    incorrectColor: tn.pink,
    incorrectColorHover: tn.pink,
    buttonColorSameAsFeedback: true,
    buttonFontWeight: '500',
    buttonFontColor: 'white',
    fontHeader: tn.fontRegular,
    fontSizeHeader: '2em',
    fontCopy: tn.fontRegular,
    fontSizeCopy: '1.5em',
    fontButton: tn.fontRegular,
    fontSizeButton: '1.2em',
  },

  feedbackPageStyle: {
    numberColor: 'black',
  },
  links: {
    // Experimental
    borderDotted: true,
    useBorder: true,
    borderOnHover: true,
    hoverColor: 'black',
  },
  strings: {
    ...common.strings,
    checkboxLabel: 'Jeg vil gjerne motta e-post når Telenor arrangerer konkurranser',
  },

  // Experimental branding from here on

  nav: `
    font-family: ${tn.fontLight};
    li:hover {
      color: #666;
    }
  `,

  mobileMenu: `
    li {
      border: 0;
      background: ${tn.blueMain};
    }
  `,

  standardPage: {
    basic: `
        font-family: ${tn.fontLight};
        `,

    h1: ` h1{
          font-size: ${tn.fontBig};
          font-weight: normal;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
          // font-family: ${tn.fontLight};
        }
        
        `,

    p: `p {
          font-size: ${tn.fontStandard};
          
        }`,
  },
  basicButton: `
      font-family: ${tn.fontRegular || 'sans-serif'};
      transition: 0.3s; /* If no themes have this, stick it in the component instead */
      box-shadow: 0;
      border: none;
      background: ${tn.blueMain};
      padding: 0.8em 1.7em 1em;
      border-radius: ${tn.borderRadius};
      font-size: 1.2em;
      font-weight: normal;
      color: white;
      border: none;
      
      &:hover {
        color: white;
        background: ${tn.blueDark};
      }
      `,

  question: `
    font-family: ${tn.fontRegular};
    font-size: ${tn.fontStandard};
    `,

  feedback: `
    font-family: ${tn.fontRegular};
    h1 {
      font-size: ${tn.fontBig}

    }
    p {
      font-size: 1.5em;
    }

    &.correct {
      h1 {
        color: ${tn.colorCorrect};
      }
      button {
        background: ${tn.colorCorrect};
      }
    }

    &.incorrect {
      h1 {
        color: ${tn.colorIncorrect};
      }
      button {
        background: ${tn.colorIncorrect};
      }
    }

  `,
  answers: `
    font-family: ${tn.fontRegular};
    background: ${tn.blueMain};
    color: white;
    border: 0;
    transition: 0;
    &:hover {
      background: ${tn.blueDark};
      // color: black;
    }
    `,
  form: `
    font-family: ${tn.fontLight};
  `,
};

const telenorDark = {
  ...common,
  ...telenorLight,
  headerHover: 'silver',
  colors: {
    ...telenorLight.colors,
    background: '#0c0f27',
    color: '#fff',
  },
  // pagesStyles: {
  // ...telenorLight.pagesStyles,
  // fontSizeCopy: '1.2em',
  // },
  strings: {
    ...common.strings,
    checkboxLabel: 'Jeg vil gjerne motta e-post når Telenor T-We arrangerer konkurranser',
  },

  // Experimental here

  standardPage: {
    ...telenorLight.standardPage,
    p: `p {
        font-size: 19px;
      }
    `,
  },

  // christmasDeco: ` {

  //   border: '4px solid red'

  // }

  // `,
};

const telenorYng = {
  ...common,
  ...telenorLight,

  nav: `
    font-family: ${tn.yngFontLight};
    li:hover {
      color: #666;
    }
  `,

  mobileMenu: `
    li {
      text-align: center;
      border: 0;
      background: black;
      font-size: ${tn.yngFontSizeMedium};
      a {
        color: white;
      }
    }
  `,

  standardPage: {
    basic: `
        font-family: ${tn.yngFontLight};
        `,

    h1: ` h1{
          font-size: ${tn.yngFontSizeLarge};
          font-weight: normal;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
          // font-family: ${tn.yngFontSizeMedium};
        }
        
        `,

    p: `p {
          font-size: ${tn.yngFontSizeSmall};
        }`,
  },

  basicButton: `
    font-family: ${tn.yngFontLight};
    background: black;
    color: white;
    padding: 0.8em 1.7em 1em;
    font-size: ${tn.yngFontSizeSmall};
    border: none;
    border: 1px solid black;
    border-radius: 3px;

  `,

  basicButtonHover: `
  
    background: white;
    color: black;
  `,

  question: `
    font-family: ${tn.yngFontLight};
    font-size: ${tn.yngFontSizeMedium};
    `,

  feedback: `
    font-family: ${tn.yngFontLight};
    h1 {
      font-size: ${tn.yngFontSizeLarge}

    }
    p {
      font-size: ${tn.yngFontSizeSmall}
    }

    button {
      border: none;
    }

    button {
      border: none;
    }

    &.correct {
 
      button {
        background: ${tn.colorCorrectYng};
        color: black;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.incorrect {
   
      button {
        background: ${tn.colorIncorrectYng};
        color: black;
          &:hover {
            text-decoration: underline;
        }
      }
    }

  `,

  answers: `
    font-family: ${tn.yngFontLight};
    font-size: ${tn.yngFontSizeSmall};
    background: black;
    color: white;
    border: 1px solid black;
    transition: 0;
    &:hover {
      background: white;
      color: black;
    }
  `,
  form: `
    font-family: ${tn.yngFontLight};
  `,
};

// Defining these variables outside the object. Later we'll find a nice solution

const hr = {
  red: '#E01C1F',
  darkRed: 'rgb(175, 10, 15)',
  darkBlue: 'rgb(9, 44, 85)',
  colorNeutral: '#1D1D1D',
  white: 'white',
  green: '#66880A',
  dark: '#3a3a3a',
  darker: '#232120',
  black: '#000',
  colorSecondary: 'blue',
  Austin: 'Austin Text',
  Atlas: 'Atlas Grotesk',
  fontSizeCopy: '20px',
  fontSizeHeader: '40px',
};

const hurtigruten = {
  ...common,
  fonts: {
    light: hr.Austin,
    default: hr.Austin,
    copy: hr.Atlas,
  },
  logoAlignCenter: false,
  headerHover: 'black',
  colors: {
    background: 'white',
    color: 'black',
    questionColor: '#fff',
    linkColor: hr.darker,
    primary: {
      default: '#19aaf8',
      darker: '#0078cf',
      lighter: '#19aaf8',
    },
  },

  card: {
    background: 'white',
    color: 'black',
    radius: '0',
  },
  header: {
    mobileMenuFont: hr.Atlas,
    mobileMenuFontColor: hr.darkBlue,
    mobileNavDividerColor: hr.darkBlue,
  },
  frontPage: {
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
    fontCopy: hr.Atlas,
  },
  pagesStyles: {
    fontHeading: hr.Austin,
    fontSizeHeading: '2.5em',
    fontHeadingWeight: '600',
    fontSizeCopy: '1.2em',
    fontSizeButton: '1.2em',
    fontCopy: hr.Atlas,
    fontCopyWeight: 'light',
  },
  buttons: {
    radius: '0px',
    hover: hr.darkRed,
    font: hr.Atlas,
    fontSize: hr.fontSizeCopy,
    padding: '0.8em 1.7em 1em',
    weight: '700',
    buttonTextColor: 'white',
  },
  questionStyle: {
    fontQuestion: hr.Atlas,
    fontAnswer: hr.Atlas,
    fontColor: hr.white,
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
    fontSizeQuestion: '30px',
    fontSizeAnswer: hr.fontSizeCopy,
    answerButtonRadius: '0',
    answerTextColor: hr.white,
    answerTextWeight: 700,
  },
  feedbackStyle: {
    correctColor: hr.green,
    correctColorHover: hr.green,
    incorrectColor: hr.darkRed,
    incorrectColorHover: hr.darkRed,
    buttonColor: hr.darkBlue,
    buttonColorHover: hr.darkBlue,
    buttonColorSameAsFeedback: false,
    buttonFontWeight: '500',
    buttonFontColor: 'white',
    fontHeader: hr.Austin,
    fontSizeHeader: '40px',
    fontCopy: hr.Atlas,
    fontHeightCopy: 1.3,
    fontSizeCopy: '20px',
    fontButton: hr.Atlas,
    fontSizeButton: '20px',
    fontLinkColor: hr.black,
    noTextDecorationOnLinkHover: true,
  },
  feedbackPageStyle: {
    numberColor: '#092c55',
  },
  links: {
    // Experimental
    borderDotted: true,
    useBorder: true,
    borderOnHover: true,
    hoverColor: 'black',
  },
  formStyle: {
    font: hr.Atlas,
    fontSize: '1.2em',
    buttonColor: hr.red,
    buttonColorHover: hr.darkRed,
  },
  confettiColors: [hr.red, hr.darkRed, hr.white, '#000', hr.green],
  strings: {
    ...common.strings,
    startBtnLabel: 'Start',
    restartBtnLabel: 'Restart',
    proceedBtnLabel: 'Proceed',
    submitBtnLabel: 'Submit',
    submittingBtnLabel: 'Submitting...',
    submitErrorMessage: 'Oops! Something went wrong, and the form was not submitted',
    checkboxLabel: 'I’m fine with Hurtigruten sharing my story!',
    formId: 'hurtigruten',
    inactiveQuizLabel: 'The quiz is no longer active.',
  },

  // New paradigm

  nav: `
    font-family: ${hr.Atlas};
    li:hover {
      color: ${hr.dark};
    }
  `,

  mobileMenu: `
    li {
      padding: 0;
      font-family: ${hr.Atlas};
      background: ${hr.red};
      a {
        color: white;
      }
    }
  `,

  standardPage: {
    basic: `
        font-family: ${hr.Austin};
        `,

    h1: ` h1{
          font-size: 2.5em;
          font-weight: 600;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
        }`,

    h2: ` h2 {
        }
        
        `,

    p: `p {
          font-family: ${hr.Atlas};
          font-size: 1.2em;
          line-height: 1.4;
        }`,
  },

  basicButton: `
  font-family: ${hr.Atlas};
  background: ${hr.red};
  color: white;
  padding: 0.8em 1.7em 1em;
  font-size: 20px;
  font-weight: 700;
  border: none;
  border-radius: none;
  transition: .3s;
  `,

  basicButtonHover: `
    background: ${hr.darkRed};
  
  `,

  question: `
    font-family: ${hr.Atlas};
    font-size: 30px;
    `,

  feedback: `
    font-family: ${hr.Austin};
    h1 {
      font-size: 40px;
      
    }
    p {
      font-size: 20px;
      font-family: ${hr.Atlas};

    }

    &.correct {
      h1 {
        color: ${hr.green};
      }
      button {
        background: ${hr.darkBlue};
      }
    }

    &.incorrect {
      h1 {
        color: ${hr.darkRed};
      }
      button {
        background: ${hr.darkBlue};
      }
    }
  `,
  answers: `
    font-family: ${hr.Atlas};
    background: ${hr.red};
    color: white;
    border: none;
    transition: 0;
    &:hover {
      background: ${hr.darkRed};
    }
  `,
};

const hurtigrutenGroup = {
  ...hurtigruten,
  frontPage: {
    borderColor: hr.dark,
    borderColorHover: hr.dark,
    buttonTextColor: hr.black,
    buttonTextColorHover: hr.white,
    buttonColor: hr.white,
    buttonColorHover: hr.dark,
  },
  buttons: {
    ...hurtigruten.buttons,
    // radius: '0px',
    // hover: hr.darkRed,
    // font: hr.Atlas,
    // fontSize: hr.fontSizeCopy,
    // padding: '0.8em 1.7em 1em',
    weight: '600',
    buttonTextColor: hr.dark,
    transition: '0s',
  },
  pagesStyles: {
    fontHeading: hr.Atlas,
    fontSizeHeading: '2.5em',
    fontHeadingWeight: '700',
    fontSizeCopy: '1.2em',
    fontSizeButton: '1.2em',
    fontCopy: hr.Atlas,
    fontCopyWeight: 'light',
  },
  questionStyle: {
    ...hurtigruten.questionStyle,

    // fontQuestion: hr.Atlas,
    fontQuestionWeight: 700,
    // fontAnswer: hr.Atlas,
    fontColor: hr.darker,
    buttonColor: hr.white,
    buttonBorderColor: hr.darker,
    answerTextColor: hr.darker,
    // answerButtonRadius: '0',
    // answerTextWeight: 700,

    buttonColorHover: hr.darker,
    buttonTextColorHover: hr.white,
    // fontSizeQuestion: '30px',
    // fontSizeAnswer: hr.fontSizeCopy,
  },
  feedbackStyle: {
    ...hurtigruten.feedbackStyle,
    fontHeader: hr.Atlas,

    buttonColor: hr.white,
    buttonColorHover: hr.darker,
    buttonColorSameAsFeedback: false,
    buttonFontWeight: '500',
    buttonFontColor: hr.darker,
  },
  feedbackPageStyle: {
    numberColor: hr.red,
  },
  links: {
    // Experimental
    // color: hr.red,
    // borderDotted: true,
    borderOnHover: true,
    borderDotted: true,

    // useBorder: true,
    // borderOnHover: true,

    hoverColor: hr.darker,
  },
  formStyle: {
    ...hurtigruten.formStyle,
    // font: hr.Atlas,
    // fontSize: '1.2em',
    buttonTextColor: hr.dark,
    buttonTextColorHover: hr.white,
    buttonColor: hr.white,
    buttonColorHover: hr.dark,
    borderColor: hr.dark,
  },

  // New paradigm

  mobileMenu: `
    li {
      padding: 0;
      font-family: ${hr.Atlas};
      // background: ${hr.red};

      // background: black;

      background: white;
      border: 1px solid black;
      a {
        color: black;
      }
    }
  `,

  standardPage: {
    ...hurtigruten.standardPage,
    h1: `h1{
          font-family: ${hr.Atlas};
          font-size: 2.5em;
          font-weight: 700;
          margin-bottom: 20px; // Stuff like this could be put in a variable too.
       
        }`,
  },

  basicButton: `
  font-family: ${hr.Atlas};
  background: white;
  color: black;
  padding: 0.8em 1.7em 1em;
  font-size: 20px;
  font-weight: 700;
  border: 1px solid ${hr.dark};
  border-radius: none;
  transition: .3s;
  `,

  basicButtonHover: `
    background: ${hr.dark};
    color: white;
  
  `,

  question: `
    font-family: ${hr.Atlas};
    font-size: 30px;
    font-weight: 700;
    `,

  answers: `
    font-family: ${hr.Atlas};
    // background: ${hr.red};
    background: white;
    color: black;
    // border: none;
    border: 1px solid black;
    transition: 0;
    &:hover {
      background: ${hr.black};
    }
  `,
  feedback: `
    font-family: ${hr.Austin};
    h1 {
      font-size: 40px;
      font-family: ${hr.Atlas};
      
    }
    p {
      font-size: 20px;
      font-family: ${hr.Atlas};

    }
    button {
      background: white;
      border: 1px solid black;
      color: ${hr.dark};
      font-weight: 500;
      &:hover {
        background: ${hr.darker};
        color: white;
      }
    }

    &.correct {
      h1 {
        color: ${hr.green};
      }
      button {
        background: white;
        border: 1px solid black;
        color: ${hr.dark};
        font-weight: 500;
        &:hover {
          background: black;
          color: white;
        }
      }
    }

    &.incorrect {
      h1 {
        color: ${hr.darkRed};
      }
    }
  `,
};

/* we export all the themes as key7values in a single object */
export const brandThemes = {
  evokedsetBasic,
  telenorDark,
  telenorLight,
  telenorYng,
  hurtigruten,
  hurtigrutenGroup,
};
