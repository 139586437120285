import React from 'react';
import { bool, array } from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { useStateValue } from '../../state';
import PortableText from '../PortableText';

const AnswerButtonStyle = styled.button`
  font-family: var(--fontAnswer), serif};
  color: var(--answerTextColor, #333);
  /* color: white; */
  font-size: var(--fontSizeAnswer, 1.2em);
  background: var(--buttonColor, #ddd);
  font-weight: var(--answerTextWeight, normal);
   border: 1px solid ${p => p.theme?.sanity?.brand?.questionStyle?.buttonBorderColor || 'none'};

  ${p =>
    p.theme?.sanity?.brand?.questionStyle?.buttonBorderColor
      ? 'border: 1px solid  ' + p.theme?.sanity?.brand?.questionStyle?.buttonBorderColor
      : 'border: none'};



  &:hover {
    background: var(--buttonColorHover, #333);
    color:var(--buttonTextColorHover, white);
  }
  border-radius: var(--answerButtonRadius, 3px);


  margin: 0.8rem 0;
  padding: 0 1rem;
  transition: 0.3s;
  cursor: pointer;
  * {
    pointer-events: none;
  }

  @media (max-width: 360px) {
    font-size: 16px;
  }






${p => p.theme?.sanity?.brand?.answers}





`;

const Answer = function ({ isCorrect, answerText, feedbackText, theme }) {
  const {
    sanity: {
      brand: { questionStyle },
    },
  } = theme;
  const [{ currentPage }, dispatch] = useStateValue();
  function handleAnswer() {
    if (isCorrect) dispatch({ type: 'bumpScore' });
    if (!feedbackText) {
      dispatch({ type: 'moveForward' });
      return;
    }
    // console.log('Question ' + currentPage);
    dispatch({
      type: 'setFeedback',
      payload: { content: feedbackText, isCorrect },
    });
  }
  return (
    <AnswerButtonStyle
      onClick={handleAnswer}
      style={{
        '--buttonTextColor': questionStyle?.buttonTextColor,
        '--fontAnswer': questionStyle?.fontAnswer,
        '--buttonColor': questionStyle?.buttonColor,
        '--buttonColorHover': questionStyle?.buttonColorHover,
        '--fontSizeAnswer': questionStyle?.fontSizeAnswer,
        '--fontColor': questionStyle?.fontColor,
        '--answerButtonRadius': questionStyle?.answerButtonRadius,
        '--answerTextColor': questionStyle?.answerTextColor,
        '--answerTextWeight': questionStyle?.answerTextWeight,
      }}
    >
      <PortableText className="btn-content" content={answerText} />
    </AnswerButtonStyle>
  );
};

Answer.defaultProps = {
  isCorrect: false,
};

Answer.propTypes = {
  answerText: array.isRequired,
  feedbackText: array.isRequired,
  isCorrect: bool,
};
export default withTheme(Answer);
