import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { createFire } from '../../lib/firestarter';

export async function postToApi(userInput) {
  console.log({ userInput });
  const uuid = uuidv4();
  const _id = `userdata.${uuid}`;
  const doc = {
    _type: 'form.submit',
    _id,
    ...userInput,
  };
  console.log('posting');
  const res = await axios.post('/api/submitForm', doc);
  return res;
}

export async function postToFirebaseAPI(userInput) {
  // const uuid = uuidv4();

  // console.log('Yup, we are on our way to firebase');

  // console.log({ userInput });

  // const res = await axios.post('/api/submitFormFirebase', userInput);

  // console.log({ res });
  // return null;
  // return res;

  const title = userInput.displayTitle
    ? 'Results: ' + userInput.displayTitle
    : 'Unknown ' + userInput.length;

  const fbResponse = await createFire(title, userInput);
  console.log({ fbResponse });
  return fbResponse;
}
