import React, { useState, useEffect } from 'react';
import { useEffectOnce } from 'react-use';
import { Flex } from '@sanity/ui';
import { func } from 'prop-types';

import { useStateValue } from '../../../state';
import { postToApi } from '../../Form/fns';
import Panel from './Panel';
import BoolSwitch from './BoolSwitch';
import IntClicker from './IntClicker';

const DevPanel = ({ exitStrategy }) => {
  const [
    {
      score,
      currentPage,
      quiz: { _id },
    },
    dispatch,
  ] = useStateValue();
  const [outline, setOutline] = useState(false);
  useEffectOnce(() => {
    // eslint-disable-next-line
    document.body.classList.add('dbg-outline');
  });
  useEffect(() => {
    // eslint-disable-next-line
    document.body.classList.toggle('dbg-outline');
  }, [outline]);
  const crementScore = [
    () => dispatch({ type: 'nerfScore' }),
    () => dispatch({ type: 'bumpScore' }),
  ];
  const crementPage = [
    () => dispatch({ type: 'setCurrentPage', payload: currentPage - 1 }),
    () => dispatch({ type: 'setCurrentPage', payload: currentPage + 1 }),
  ];

  async function handleClick() {
    const res = await postToApi({
      fullName: 'Sinjoro Ajnulo',
      phoneNumber: '12345678',
      email: 'la@di.da',
      acceptedTerms: true,
      docRef: { _type: 'reference', _ref: _id },
    });
    // console.log('res: ', res);
  }

  return (
    <Panel id="dbg-panel" closeHandler={exitStrategy}>
      <Flex direction="column" justify="space-between" align="flex-start" style={{ width: '100%' }}>
        <IntClicker value={score} label="Score" crementFunctions={crementScore} />
        <IntClicker value={currentPage} label="Page" crementFunctions={crementPage} />
        <BoolSwitch
          label="Outline Elements"
          value={outline}
          clickHandler={() => setOutline(!outline)}
        />

        <button type="button" onClick={handleClick}>
          Debug api
        </button>

        <span
          style={{
            fontSize: '.6em',
            color: '#333',
            position: 'absolute',
            bottom: '5px',
            left: 'calc(50% - 10px)',
          }}
        >
          v2.0.3
        </span>
      </Flex>
    </Panel>
  );
};

DevPanel.propTypes = {
  exitStrategy: func.isRequired,
};

export default DevPanel;
