/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* FIXME: Make the clickable element a button to resolve these linting errors */
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { useStateValue } from '../state';

const VideoContainer = styled.div`
  margin: 0 auto;
  position: relative;
`;

const FullScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  div {
    width: 100vw;
    height: 100vh;
    video {
      width: 100%;
    }
  }
`;

const defaults = {
  playing: true,
};

const VideoPlayer = ({ url, ...props }) => {
  const [full, setFull] = useState(true);
  const [{ fullScreenRef, feedback }] = useStateValue();
  if (!fullScreenRef?.current || !full) {
    return (
      <VideoContainer>
        {!feedback && (
          <ReactPlayer
            url={url}
            controls
            width="100%"
            height="100%"
            // onEnded={() => setFull(false)}
            {...defaults}
            {...props}
          />
        )}
      </VideoContainer>
    );
  }
  return createPortal(
    <FullScreenContainer isPortal>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        onEnded={() => setFull(false)}
        {...defaults}
        {...props}
      />
      <span
        style={{
          fontSize: 30,
          color: 'white',
          position: 'fixed',
          top: 40,
          left: 20,
          cursor: 'pointer',
        }}
        onClick={() => setFull(false)}
        onKeyPress={() => setFull(false)}
        role="button"
        tabIndex={0}
      >
        Lukk fullskjermvisning
      </span>
    </FullScreenContainer>,
    fullScreenRef.current,
  );
};

export default VideoPlayer;
