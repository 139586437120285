import React, { useEffect } from 'react';
import { bool, string } from 'prop-types';
import * as Yup from 'yup';
import { Card, Stack } from '@sanity/ui';
import styled, { withTheme } from 'styled-components';
import { TextInput, Checkbox, TextArea } from './Inputs';
import AlternativeButton from '../AlternativeButton';
import { useStateValue } from '../../state';

export const initialValues = {
  telenor: {
    fullName: '',
    phoneNumber: '',
    email: '',
    acceptedTerms: false,
    notCheated: true,
    textInput: '',
    context: 'defaultvalue',
  },
  hurtigruten: {
    fullName: '',
    phoneNumber: '',
    email: '',
    acceptedTerms: false,
    textfield: '',
    notCheated: true,
    context: '',
  },
};

const StyledForm = styled(Stack)`
  ${p => p.theme?.sanity?.brand?.form}

  .christmasStyle {
    margin: 1em -2em;
    padding: 2em;
    background: #0c0f27
      url(https://cdn.sanity.io/images/4kn9ntmr/production/70c455a8be62c334669e1bcbde1c0ac81e2917bd-1000x136.png)
      left bottom no-repeat;
    color: white;
    background-size: contain;
    padding-bottom: 7em;
    padding-top: 1px;
  }

  @media (max-width: 500px) {
    .christmasStyle {
      margin: 1em -24px;
      padding: 24px;
      padding-bottom: 6em;
      padding-top: 1px;
    }
  }
`;

// const ChristmasStyles =
// console.log({ theme });

export const validationSchema = {
  telenor: Yup.object({
    fullName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
    phoneNumber: Yup.string().min(8, 'Minst 8 siffer').required('Obligatorisk felt!'),
    email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
    acceptedTerms: Yup.boolean().oneOf([true, false]),
  }),

  telenorTextInput: Yup.object({
    fullName: Yup.string().min(3, 'Minst tre bokstaver').required('Obligatorisk felt!'),
    phoneNumber: Yup.string().min(8, 'Minst 8 siffer').required('Obligatorisk felt!'),
    email: Yup.string().email('Ugyldig epostadresse').required('Obligatorisk felt!'),
    acceptedTerms: Yup.boolean().oneOf([true, false]),
    // textinput: Yup.string()
    //   .min(2, 'Må være minst 2 bokstaver')
    //   .max(40, 'Maximum 40 tegn')
    //   .required('Dette feltet er påkrevd'),
  }),

  hurtigruten: Yup.object({
    fullName: Yup.string()
      .min(3, 'Must be at least 3 characters')
      .required('This field is required!'),
    phoneNumber: Yup.string().min(8, 'Must have 8 digits'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('This field is required!'),
    acceptedTerms: Yup.boolean().oneOf([true, false]),
    // textfield: Yup.string()
    //   .min(3, 'Must be at least 3 characters')
    //   .max(150, 'Maximum 150 characters'),
    // .required('This field is required!'),
  }),
  hrTextfield: Yup.object({
    fullName: Yup.string()
      .min(3, 'Must be at least 3 characters')
      .required('This field is required!'),
    phoneNumber: Yup.string()
      .min(8, 'Must have at least 8 digits')
      .required('Phone number is required!'),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('This field is required!'),
    acceptedTerms: Yup.boolean().oneOf([true], 'This box must be checked!'),
    textfield: Yup.string()
      .min(3, 'Must be at least 3 characters')
      .max(320, 'Maximum 320 characters')
      .required('This field is required!'),
  }),
};

const ConfiguredForm = ({ formId, isSubmitting, theme }) => {
  const [{ quiz, formKey, notCheated, itsChristmas }, dispatch] = useStateValue();

  const altForm = quiz.formKey ? quiz.formKey : '';

  useEffect(() => {
    // Stick the formKey in the global state
    if (formKey === null) {
      dispatch({ type: 'formKey', payload: altForm });
    }
    console.log({ notCheated });

    // This is a hack for achieving Christmas look for T-We
    if (quiz._id === '4507f62d-cd17-4c34-a682-78496d2db6b6') {
      dispatch({ type: 'setItsChristmas', payload: true });
      console.log({ itsChristmas });
    }
  }, []);

  const {
    sanity: {
      brand: {
        strings: { checkboxLabel, submitBtnLabel, submittingBtnLabel },
      },
    },
  } = theme;

  // This is an attempt to style the form for Telenor differently

  // const christmasStyleee = theme?.sanity?.brand?.christmasDeco;
  // // console.log({ theme });
  // console.log(christmasStyleee);

  const christmasStyles = {
    // margin: '1em -2em',
    // padding: '2em',
    // background:
    //   '#0c0f27 url(https://cdn.sanity.io/images/4kn9ntmr/production/70c455a8be62c334669e1bcbde1c0ac81e2917bd-1000x136.png) left bottom no-repeat ',
    // color: 'white',
    // backgroundSize: 'contain',
    // paddingBottom: '6em',
  };

  // I'll also check if I get the altForm. If I do, I'll serve up yet a different form.

  if (altForm && altForm === 'hr-textfield') {
    return (
      <StyledForm className="customForm" padding={4}>
        <Card>
          <TextArea
            label="Your moment (max 320 characters):"
            name="textfield"
            type="textarea"
          ></TextArea>
        </Card>
        <Card>
          <TextInput label="Name" name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Phone number" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Email" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          <Checkbox name="acceptedTerms">
            <p>{checkboxLabel}</p>
          </Checkbox>
        </Card>

        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledForm>
    );
  }

  if (altForm && altForm === 'telenor-textinput') {
    return (
      <StyledForm className="customForm" padding={4}>
        <Card className="christmasStyle">
          <TextInput label="Svar" name="textInput" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Navn" name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Telefonnummer" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="E-post" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          <Checkbox name="acceptedTerms">
            <p>{checkboxLabel}</p>
          </Checkbox>
        </Card>
        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledForm>
    );
  }

  if (formId === 'hurtigruten') {
    return (
      <StyledForm className="customForm" padding={4}>
        <Card>
          <TextInput label="Name" name="fullName" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Phone (optional)" name="phoneNumber" type="text" placeholder="" />
        </Card>
        <Card>
          <TextInput label="Email" name="email" type="email" placeholder="" />
        </Card>
        <Card>
          {/* <Card>
            <Checkbox name="acceptedTerms">
              <p>{checkboxLabel}</p>
            </Checkbox>
          </Card> */}
        </Card>

        <Card>
          <AlternativeButton disabled={isSubmitting} type="submit">
            {isSubmitting ? submittingBtnLabel : submitBtnLabel}
          </AlternativeButton>
        </Card>
      </StyledForm>
    );
  }
  return (
    <StyledForm className="customForm" padding={4}>
      <Card>
        <TextInput label="Navn" name="fullName" type="text" placeholder="" />
      </Card>
      <Card>
        <TextInput label="Telefonnummer" name="phoneNumber" type="text" placeholder="" />
      </Card>
      <Card>
        <TextInput label="E-post" name="email" type="email" placeholder="" />
      </Card>
      <Card>
        <Card>
          <Checkbox name="acceptedTerms">
            <p>{checkboxLabel}</p>
          </Checkbox>
        </Card>
      </Card>
      <Card>
        <AlternativeButton disabled={isSubmitting} type="submit">
          {isSubmitting ? submittingBtnLabel : submitBtnLabel}
        </AlternativeButton>
      </Card>
    </StyledForm>
  );
};

ConfiguredForm.defaultProps = {
  formId: 'telenor',
  isSubmitting: false,
  checkboxLabel: 'Jeg vil gjerne motta e-post om konkurranser',
};

ConfiguredForm.propTypes = {
  formId: string,
  isSubmitting: bool,
  checkboxLabel: string,
};

export default withTheme(ConfiguredForm);
