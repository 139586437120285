/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { object, shape } from 'prop-types';
import { graphql } from 'gatsby';
import EnchantQuiz from '../cpt/EnchantQuiz';
import SEO from '../cpt/SEO';
import { useStateValue } from '../cpt/EnchantQuiz/state';
import { rebuildQuizData } from '../cpt/EnchantQuiz/lib/sanity';

// eslint-disable-next-line react/prop-types
const EnchantQuizTemplate = ({ data }) => {
  // console.log('data', data.quiz);
  const [{ quiz }, dispatch] = useStateValue();
  // eslint-disable-next-line react/prop-types
  const qData = data?.quiz;

  async function setAppState() {
    if (!quiz && qData) {
      dispatch({
        type: 'setQuiz',
        payload: rebuildQuizData(qData),
      });
      dispatch({
        type: 'setActive',
        payload: qData?.active,
      });
    }
  }
  useEffect(() => {
    setAppState();
  }, [qData]);

  // console.log({ qData });
  setTimeout(setAppState, 10);
  return (
    <div>
      <SEO
        url={qData?.meta?.ogUrl || ''}
        title={qData?.meta?.ogTitle}
        description={qData?.meta?.ogDescription}
        image={qData?.meta?.ogImage?.asset?.url}
        themeKey={qData?.themeKey}
      />
      <EnchantQuiz />
    </div>
  );
};

EnchantQuiz.defaultProps = {
  data: {
    quiz: {},
  },
};

EnchantQuiz.propTypes = {
  data: shape({
    quiz: object.isRequired,
  }),
};

// This is the query to sanity. The query below is a
// groq query. Where it it executed?
// Correction - this is a graphql query. Not groq.

export const sanityQuizQuery = graphql`
  query SanityQuizTemplateQuery($id: String!) {
    quiz: sanityQuiz(_id: { eq: $id }) {
      displayTitle
      _id
      _key
      active
      meta {
        ogUrl
        ogTitle
        ogDescription
        ogImage {
          _key
          asset {
            url
          }
        }
      }
      _rawLogo(resolveReferences: { maxDepth: 10 })
      _rawBackgroundImage(resolveReferences: { maxDepth: 10 })
      _rawOtherPages(resolveReferences: { maxDepth: 10 })
      _rawPostPages(resolveReferences: { maxDepth: 10 })
      _rawPrePages(resolveReferences: { maxDepth: 10 })
      _rawQuestions(resolveReferences: { maxDepth: 10 })
      themeKey
      formKey
      customStartButtonText
      formToFirebase
    }
  }
`;

export default EnchantQuizTemplate;
