import React from 'react';
import { Flex, Box, Label, Button } from '@sanity/ui';
import { FaPlus as PlusIcon, FaMinus as MinusIcon } from 'react-icons/fa';
import { string, number, arrayOf, func } from 'prop-types';
import TwoCols from './TwoCols';

const IntClicker = ({ label, value, crementFunctions }) => {
  // TODO: Add min / max values
  return (
    <TwoCols label={label}>
      <Box style={{ width: 70 }}>
        <Flex justify="space-between">
          <Button
            padding={1}
            radius={5}
            mode="ghost"
            style={{ paddingBottom: 0, paddingTop: 5 }}
            icon={MinusIcon}
            onClick={crementFunctions[0]}
          />
          <Label
            disabled
            paddingY={1}
            paddingX={2}
            radius={5}
            style={{ paddingTop: 5, fontSize: 16, fontWeight: 'bold', margin: 2 }}
          >
            {`${value}`}
          </Label>
          <Button
            padding={1}
            radius={5}
            mode="ghost"
            style={{ paddingBottom: 0, paddingTop: 5 }}
            icon={PlusIcon}
            onClick={crementFunctions[1]}
          />
        </Flex>
      </Box>
    </TwoCols>
  );
};

IntClicker.defaultProps = {
  label: 'Mystery Int',
};

IntClicker.propTypes = {
  label: string,
  value: number.isRequired,
  crementFunctions: arrayOf(func).isRequired,
};

export default IntClicker;
