import client from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import sanityConfig from '../../../../sanityConfig';

// REVIEW: Is this file deprecated? @martin

// eslint-disable-next-line no-undef
const dataset = 'production';
const projectId = process.env.GATSBY_SANITY_ID;

export const blockConfig = { projectId, dataset };
export const sanityClient = client({
  ...sanityConfig,
  useCdn: true,
});

const builder = imageUrlBuilder(sanityClient);

export function urlFor(source) {
  return builder.image(source);
}

// I've commented this function out, and the app still works!

// export async function fetchQuiz(slug, dispatch) {
//   // This is the Sanity Query
//   // Is it even run?
//   const query =
//     slug && slug.length > 0 ? `${''}*[_type == "quiz" && slug.current=="${slug}"]{...}[0]` : null;
//   // : `${''}*[_type == "quiz"]{...}[0]`;
//   const res = await sanityClient.fetch(query);
//   console.log({ res });
//   dispatch({ type: 'setQuiz', payload: res });
// }

export const anonClient = client({
  projectId,
  dataset,
  apiVersion: '2021-03-25',
  useCdn: true,
});

export async function fetchUserData() {
  const query = `${''}*[_type == "form.submit"]{...}`;
  const res = await anonClient.fetch(query);
  return res;
}

export const rebuildQuizData = quiz => {
  // This function does as follows:
  // Take the quiz data, reformats and returns it.
  // Where does it come from?
  const {
    _rawBackgroundImage: backgroundImage,
    _rawLogo: logo,
    _rawOtherPages: otherPages,
    _rawPrePages: prePages,
    _rawPostPages: postPages,
    _rawQuestions: questions,
    meta,
    themeKey,
    formKey,
    displayTitle,
    _id,
    active,
    customStartButtonText,
    formToFirebase,
  } = quiz;
  const restoredQuiz = {
    _id,
    backgroundImage,
    meta,
    displayTitle,
    themeKey,
    formKey,
    questions,
    prePages,
    postPages,
    otherPages,
    logo,
    active,
    customStartButtonText,
    formToFirebase,
  };
  // console.log({ restoredQuiz });
  return restoredQuiz;
};
