/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import styled, { withTheme } from 'styled-components';
import { StyledFormWrap } from './Inputs';

import ConfiguredForm, { initialValues, validationSchema } from './ConfiguredForm';
import { postToApi, postToFirebaseAPI } from './fns';

import { useStateValue } from '../../state';
import { useLocation } from 'react-use';
import { extractURLParams } from '../../lib/utils';

const CustomStyledForm = styled(StyledFormWrap)`
  padding: 0;
  .customForm {
    padding: 0 2em 1em;
    padding: 0;
  }
  /* max-width: 560px; */
  // Staale - I changed this to 500px. Chestertons fence.
  max-width: 500px;

  form label {
    font-size: 16px;
    margin: 10px 0 5px;
    &.checkbox-input {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 30px auto;
      input {
        // How do I style the colour of this check button?
        width: 20px;
        height: 20px;
        margin-left: 0;
        margin-top: 1px;
      }
      .inputContainer {
      }
      p {
        /* border: 4px solid red; */
        margin-top: 0;
      }
    }
  }

  @media (max-width: 500px) {
    .customForm {
      padding: 0;
    }
  }
  // TODO: This styling should have been in the input doc. But They didn't take.
  .textarea {
    min-height: 180px;
    font-size: 16px;
    font-family: inherit;
    font-style: italic;
  }
`;

const SignupForm = ({ theme }) => {
  const {
    sanity: {
      brand: {
        strings: { formId = 'telenor', submitErrorMessage, checkboxLabel },
      },
    },
  } = theme;
  const [submitFailed, setSubmitFailed] = useState(false);
  const [
    {
      // quiz: { _id, displayTitle },
      quiz: { _id, formToFirebase, displayTitle },
      score,
      formKey,
      notCheated,
    },
    dispatch,
  ] = useStateValue();

  // if special form exists use that formId to get the validation schema
  // console.log({ formToFirebase, _id });

  let temporaryValidationSchema = '';

  if (formKey === 'hr-textfield') {
    // If a special form is present, set the temporary Form Stand In
    temporaryValidationSchema = 'hrTextfield';
  } else if (formKey === 'telenor-textinput') {
    temporaryValidationSchema = 'telenorTextInput';
  } else {
    temporaryValidationSchema = formId;
  }

  let temporaryValues = initialValues[formId];

  temporaryValues['notCheated'] = notCheated;

  const { search: urlString } = useLocation();

  const urlParameters = extractURLParams(urlString);

  // console.log(urlParameters);

  const contextParameter = urlParameters.context ? urlParameters.context : false;

  // Staale Start here. I can now differentiate between the two calls. Let's move on to configuring the firebase call.

  const postWhere = (values, actions) => {
    if (formToFirebase) {
      // console.log('were posting somewhere else');
      // console.log({ values, actions });
      postToFirebaseAPI({
        docRef: { _type: 'reference', _ref: _id },
        score,
        displayTitle,
        consentMessage: checkboxLabel,
        ...values,
      }).then(res => {
        if (res.response?.body?.error) {
          console.log({ res });
          console.log(res.response.body.error);
          setSubmitFailed(true);
        } else {
          actions.setSubmitting(false);
          actions.resetForm();
          dispatch({ type: 'moveForward' });
        }
      });
    } else {
      // console.log('we are posting to sanity');
      postToApi({
        // endpoint: formId,
        docRef: { _type: 'reference', _ref: _id },
        score,
        consentMessage: checkboxLabel,
        ...values,
        context: contextParameter,
      }).then(res => {
        if (res.response?.body?.error) {
          console.log(res.response.body.error);
          setSubmitFailed(true);
        } else {
          actions.setSubmitting(false);
          actions.resetForm();
          dispatch({ type: 'moveForward' });
        }
      });
    }
  };

  return (
    <CustomStyledForm>
      {!submitFailed ? (
        <Formik
          initialValues={initialValues[formId]}
          // replaced formId with temporaryvalidationschema
          validationSchema={validationSchema[temporaryValidationSchema]}
          // onSubmit={(values, actions) => {
          //   postToApi({
          //     // endpoint: formId,
          //     docRef: { _type: 'reference', _ref: _id },
          //     score,
          //     consentMessage: checkboxLabel,
          //     ...values,
          //   }).then(res => {
          //     if (res.response?.body?.error) {
          //       console.log(res.response.body.error);
          //       setSubmitFailed(true);
          //     } else {
          //       actions.setSubmitting(false);
          //       actions.resetForm();
          //       dispatch({ type: 'moveForward' });
          //     }
          //   });
          // }}

          onSubmit={postWhere}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <ConfiguredForm isSubmitting={isSubmitting} formId={formId} />
              </Form>
            );
          }}
        </Formik>
      ) : (
        <h3 style={{ color: 'darkred', padding: '0 1em' }}>{submitErrorMessage}</h3>
        // TODO: Add an alert here – firebase, logdna, email, SMS.
        // TODO: Also, this needs to be language specific
      )}
    </CustomStyledForm>
  );
};

export default withTheme(SignupForm);
