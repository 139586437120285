import React from 'react';
import { string, array, object, bool } from 'prop-types';
import styled, { withTheme } from 'styled-components';
import PortableText from './PortableText';
import ProceedButton from './ProceedButton';
import { urlFor } from '../lib/sanity';

// Comment: Sometimes we use theme values directly and sometimes we use css variables.
// I'm not sure that there is a reason.

const Pagestyle = styled.div`
  &.right,
  &.left {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }

  &.top,
  &.bottom {
    display: block;
    .imageBox {
      margin: -2em -2em 1em;
    }

    max-width: 500px;
  }

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  /* h1 {
    /*  Using theme without withTheme wrapper */
  /* font-family: var(--fontHeading, 'sans-serif');
    font-size: var(--fontSizeHeading, 2.5em);

    font-weight: normal;
    margin-bottom: 20px;
  }  */

  /* h2 {
    font-family: var(--fontHeading, 'sans-serif');
  }
  p {
    font-family: var(--fontCopy, 'sans-serif');
    font-size: var(--fontSizeCopy, 1.05em);
  } */

  a {
    color: ${p => p.theme?.sanity?.brand?.colors?.linkColor || 'lightblue'};
    text-decoration: none;
    border-bottom: 1px dotted ${p => p.theme?.sanity?.brand?.colors?.linkColor || 'lightblue'};
    &:hover {
      border-color: darkblue;
    }

    color: ${p => p.theme?.sanity?.brand?.colors?.linkColor || 'lightblue'};
    text-decoration: none;

    // The border management cluster

    ${p => {
      // Do we want a bottom border or underline?
      const borderOrDeco = p.theme?.sanity?.brand?.links?.useBorder;
      const borderStyle = p.theme?.sanity?.brand?.links?.borderDotted ? 'dotted' : 'solid';
      // What colour should it be?
      const linkColor = p.theme?.sanity?.brand?.colors?.linkColor;
      // If it's deco, then we output border none and text-decoration underline
      const borderString = borderOrDeco
        ? 'border-bottom: 1px ' + borderStyle + ' ' + linkColor + ';'
        : 'border: none; text-decoration: underline;';
      return borderString;
    }}

    &:hover {
      ${p => {
        // Are we showing a border at all?
        const borderOnHover = p.theme?.sanity?.brand?.links?.borderOnHover;

        // Are we showing text underline or bottom border?
        const borderOrDeco = p.theme?.sanity?.brand?.links?.useBorder;

        // What is the hover colour?
        const hoverColor = p.theme?.sanity?.brand?.links?.hoverColor || 'black';

        // If we're not using border, simply change the colour on hover
        if (!borderOrDeco) {
          return 'border: none; color: ' + hoverColor + ';';
        }

        // If we're using border, we'll change the colour, or remove the border

        if (borderOrDeco && borderOnHover) {
          // Change the colour of the border
          return 'border-color: ' + hoverColor + ';';
        } else {
          return 'border: none;';
        }

        return false;
      }}
    }
  }

  @media (max-width: 600px) {
    &.right,
    &.left,
    &.top,
    &.bottom {
      /* replace with dynamic media query */
      display: block;
      padding: 0;
    }
  }

  h1 {
    font-weight: normal;
  }

  /* ${p => p.theme?.sanity?.brand?.experimentalBranding?.standardPage?.typography} */

  ${x => {
    // Break down typography elements into bits and put them together. The idea is to be DRYer
    if (!x.theme?.sanity?.brand?.standardPage) {
      return;
    }
    // Adhip? How to ensure that the nodes exist
    const { h1, h2, basic, p } = x.theme?.sanity?.brand?.standardPage;
    return basic + h1 + h2 + p;
  }}
`;

const NonImageStyle = styled.div`
  // TODO: This shit is stupid. Both configurations of the page should
  // be within the same box with the same potential styling.
  // @martin can you fix this?
  // Update: Why can't we just reuse the component?
  /* h1 {
    // Shitty repititishon
    /* font-family: ${p => p.theme?.sanity?.brand?.fonts?.light || 'sans-serif'}; */
  /* font-weight: normal; */
  /* font-weight: 600; */
  /* font-size: 2.5em; */
  margin-bottom: 20px;
  /* font-family: var(--fontHeading, 'sans-serif'); */
  /* font-size: var(--fontSizeHeading, 2.5em); */
  /* font-weight: var(--fontHeadingWeight, '600'); */
  /* }  */

  /* h2 {
    font-family: var(--fontHeading, 'sans-serif');
  } */

  /* p {
    font-family: ${p => p.theme?.sanity?.brand?.fonts?.copy || 'sans-serif'};
    font-family: var(--fontCopy, 'sans-serif');
    font-size: var(--fontSizeCopy, 1.05em);
    font-stretch: 100%;
    font-style: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4;
    strong {
      font-weight: 600;
    }
  } */
  h1 {
    margin-bottom: 20px;
  }
  p strong {
    font-weight: 600;
  }
  li {
    margin-left: 5px;
  }
  // Then I wouldn't have to repeat this thing

  a {
    color: ${p => p.theme?.sanity?.brand?.colors?.linkColor || 'lightblue'};
    text-decoration: none;

    // Again it's a travesty that this is duplicated
    ${p => {
      // Do we want a bottom border or underline?
      const borderOrDeco = p.theme?.sanity?.brand?.links?.useBorder;
      const borderStyle = p.theme?.sanity?.brand?.links?.borderDotted ? 'dotted' : 'solid';
      // What colour should it be?
      const linkColor = p.theme?.sanity?.brand?.colors?.linkColor;
      // If it's deco, then we output border none and text-decoration underline
      const borderString = borderOrDeco
        ? 'border-bottom: 1px ' + borderStyle + ' ' + linkColor + ';'
        : 'border: none; text-decoration: underline;';
      return borderString;
    }}

    &:hover {
      ${p => {
        // Are we showing a border at all?
        const borderOnHover = p.theme?.sanity?.brand?.links?.borderOnHover;

        // Are we showing text underline or bottom border?
        const borderOrDeco = p.theme?.sanity?.brand?.links?.useBorder;

        // What is the hover colour?
        const hoverColor = p.theme?.sanity?.brand?.links?.hoverColor || 'black';

        // If we're not using border, simply change the colour on hover
        if (!borderOrDeco) {
          return 'border: none; color: ' + hoverColor + ';';
        }

        // If we're using border, we'll change the colour, or remove the border

        if (borderOrDeco && borderOnHover) {
          // Change the colour of the border
          return 'border-color: ' + hoverColor + ';';
        } else {
          return 'border: none;';
        }

        return false;
      }}
    }
  }

  // TODO: this might be a hack since the same component is used for
  // modals and the non-image pages
  padding: 0;
  max-width: 500px;
  margin: auto;

  ${x => {
    // Break down typography elements into bits and put them together. The idea is to be DRYer
    if (!x.theme?.sanity?.brand?.standardPage) {
      return;
    }
    // Adhip? How to ensure that the nodes exist
    const { h1, h2, basic, p } = x.theme?.sanity?.brand?.standardPage;
    return basic + h1 + h2 + p;
  }}
`;

const StartIfActiveStyle = styled.div`
  > div {
    // @TODO Attempt at having an equal gap between header, paragraph and start button. Still not perfect.

    /* border: 0.01px solid white;
    background: rgba(255, 255, 25, 0.3); */
  }
`;

// eslint-disable-next-line no-unused-vars
const flexDirections = {
  left: 'row-reverse',
  bottom: 'column',
  right: 'row',
  top: 'column-reverse',
};

// eslint-disable-next-line react/prop-types
const StartIfActive = ({ isFirst, active, text, inactiveLabel, blockStartbutton }) => (
  <StartIfActiveStyle>
    <PortableText
      className="portableText"
      isFirst={isFirst}
      blockStartButton={blockStartbutton}
      content={text}
    />
    {isFirst && active && !blockStartbutton && <ProceedButton label="start" />}
    {isFirst && !active && <h3 style={{ fontStyle: 'italic' }}>{inactiveLabel}</h3>}
  </StartIfActiveStyle>
);

// eslint-disable-next-line no-unused-vars
const PageLayout = ({
  blockLayout,
  text,
  image,
  isModal,
  blockStartButton,
  isFirst,
  theme,
  active,
}) => {
  const {
    sanity: {
      brand: {
        pagesStyles,
        strings: { inactiveQuizLabel },
        experimentalBranding,
      },
    },
  } = theme;

  /* console.log(experimentalBranding.standardPage.typography); */

  if (!image) {
    return (
      <NonImageStyle
        style={{
          '--fontSizeHeading': pagesStyles?.fontSizeHeading,
          '--fontSizeCopy': pagesStyles?.fontSizeCopy,
          '--fontSizeButton': pagesStyles?.fontSizeButton,
          '--fontHeading': pagesStyles?.fontHeading,
          '--fontHeadingWeight': pagesStyles?.fontHeadingWeight,
          '--fontCopy': pagesStyles?.fontCopy,
          '--fontButton': pagesStyles?.fontButton,
        }}
      >
        <StartIfActive
          text={text}
          isFirst={isFirst}
          active={active}
          blockStartbutton={blockStartButton}
          inactiveLabel={inactiveQuizLabel}
        />
      </NonImageStyle>
    );
  }

  return (
    <Pagestyle className={blockLayout}>
      {/* <Flex direction={flexDirections[blockLayout]} align="center" padding={5}> */}
      <div className="imageBox">
        <img alt="img" width="100%" src={urlFor(image).width(700).url()} />
      </div>
      <div
        style={{
          '--fontSizeHeading': pagesStyles?.fontSizeHeading,
          '--fontSizeCopy': pagesStyles?.fontSizeCopy,
          '--fontSizeButton': pagesStyles?.fontSizeButton,
          '--fontHeading': pagesStyles?.fontHeading,
          '--fontHeadingWeight': pagesStyles?.fontHeadingWeight,
          '--fontCopy': pagesStyles?.fontCopy,
          '--fontButton': pagesStyles?.fontButton,
          // '--flexDirection': flexDirections[blockLayout],
          // experimentalBranding.standardPage.typography,
        }}
      >
        {/* // This is just for alignement purposes. Not permanent */}
        <StartIfActive
          text={text}
          isFirst={isFirst}
          active={active}
          inactiveLabel={inactiveQuizLabel}
        />
      </div>
      {/* </Flex> */}
    </Pagestyle>
  );
};

PageLayout.defaultProps = {
  image: null,
  isModal: false,
  isFirst: false,
  blockLayout: 'top',
  active: false,
};

PageLayout.propTypes = {
  text: array.isRequired,
  active: bool,
  blockLayout: string,
  image: object,
  isModal: bool,
  isFirst: bool,
};

export default withTheme(PageLayout);
