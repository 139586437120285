/* eslint-disable react/prop-types */
import React from 'react';
import { useField } from 'formik';
import styled from 'styled-components';

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  // console.log({ field });
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

export const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="textarea" {...field} {...props}></textarea>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </>
  );
};

export const Checkbox = ({ children, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div>
      <label className="checkbox-input">
        <input type="checkbox" {...field} {...props} />

        {children}
      </label>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

// Martin set some sensible resets and defaults here
export const StyledFormWrap = styled.div`
  *,
  *:before,
  *:after,
  input[type='search'] {
    box-sizing: border-box;
  }
  form {
    fieldset {
      padding: 1em 1em;
      margin: 1em 0;
    }
    legend {
      padding: 0;
      font-weight: bold;
      font-size: 1.2em;
    }
    label {
      font-size: 0.8em;
      display: block;
      margin: 1em 0;
      textarea,
      select,
      input[type='text'],
      input[type='email'],
      input[type='search'],
      input[type='tel'],
      input[type='url'],
      input[type='password'],
      input[type='color'],
      input[type='file'],
      input[type='color'],
      input[type='number'],
      input[type='range'],
      input[type='date'],
      input[type='month'],
      input[type='week'],
      input[type='time'],
      input[type='datetime'],
      input[type='datetime-local'] {
        display: block;
        width: 100%;
      }
    }
    textarea,
    select,
    input[type='text'],
    input[type='email'],
    input[type='search'],
    input[type='tel'],
    input[type='url'],
    input[type='password'],
    input[type='color'],
    input[type='file'],
    input[type='color'],
    input[type='number'],
    input[type='range'],
    input[type='date'],
    input[type='month'],
    input[type='week'],
    input[type='time'],
    input[type='datetime'],
    input[type='datetime-local'] {
      margin: 0.2em 0;
      padding: 0.8em;
      border: 1px solid #ddd;
      width: 100%;
      font-family: sans-serif;
    }

    input[type='search'] {
      box-sizing: border-box;
    }

    input[type='file'] {
      border-width: 0;
      padding: 0;
    }

    input[type='image'] {
      vertical-align: bottom;
    }
    input[type='checkbox'] {
      margin: 0.8em;
    }

    .error {
      color: ${p => p.theme?.sanity?.brand?.colors?.fail?.default || 'red'};
    }
  }
`;
