/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// FIXME: Fix these ⬆

import React from 'react';
import { Box, Flex } from '@sanity/ui';
import styled, { withTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Squash as Hamburger } from 'hamburger-react';

import NavMenu from './NavMenu';
import { urlFor } from '../lib/sanity';
import { useStateValue } from '../state';

const HeaderStyle = styled.header`
  background: var(--bgColor, #fff);
  color: var(--fgColor, #000);
  position: relative;
  display: flex;
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  flex: 0;
  z-index: 100;
  > div {
    justify-content: space-between;
  }
  &.centeredHeader {
    > div {
      justify-content: center;
    }
  }
  .mobileMenuWrapper {
    display: none;
    background: rgba(255, 255, 5, 0.3);
    position: absolute;
    width: 100%;
    left: 0;
    top: 86px;
    background: #efefef;
    height: calc(100vh - 86px);
    ul {
      flex-direction: column;
      padding: 0;
      margin-top: 0.5em;
      li {
        text-align: center;
        margin: 0.25em 0.5em;
        border-radius: 5px;
        font-size: 24px;

        a {
          padding: 1em;
          color: white;
          display: block;
        }
      }
    }
  }

  .mobileMenuWrapper {
    ul {
      // Each theme optionally modifies buttons their own way
      ${props => props.theme?.sanity?.brand?.mobileMenu};
    }
  }
`;

const Header = ({ theme }) => {
  const [
    {
      quiz: { otherPages, logo },
      menuOpen,
    },
    dispatch,
  ] = useStateValue();

  const toggleMenu = () => {
    dispatch({ type: 'setMenuOpen', payload: !menuOpen });
  };
  const isSmallScreen = useMediaQuery({ query: '(max-width: 800px)' });

  // TODO: Add justify logo // Has this already been done?

  const isCentered = theme?.sanity?.brand?.logoAlignCenter;

  return (
    <HeaderStyle
      style={{
        '--bgColor': theme?.sanity?.brand?.colors?.background,
        '--fgColor': theme?.sanity?.brand?.colors?.color,
        '--lnkColor': theme?.sanity?.brand?.colors?.linkColor,
        '--mobileMenuFont': theme?.sanity?.brand?.header?.mobileMenuFont,
        '--mobileNavDividerColor': theme?.sanity?.brand?.header?.mobileNavDividerColor,
        '--mobileMenuFontColor': theme?.sanity?.brand?.header?.mobileMenuFontColor,
      }}
      className={isCentered ? 'centeredHeader' : ''}
    >
      <Flex p={3} justify="space-between" align="center" style={{ width: '100%' }}>
        <Flex margin={2} align="center" style={{ height: 50 }}>
          {logo && (
            <img
              style={{
                marginLeft: '0',
                maxHeight: '41px',
                cursor: 'pointer',
              }}
              alt="logo"
              src={urlFor(logo).invert().height(82).url()}
              onClick={() => dispatch({ type: 'resetQuiz' })}
            />
          )}
        </Flex>
        {!isSmallScreen ? (
          <Box mx="auto">{otherPages && <NavMenu theme={theme} pages={otherPages} />}</Box>
        ) : (
          <div>
            {otherPages && (
              <>
                <Hamburger size={25} toggled={menuOpen} toggle={toggleMenu} />
                <div className="mobileMenuWrapper" style={{ display: menuOpen ? 'block' : 'none' }}>
                  <Box mx="auto">{otherPages && <NavMenu theme={theme} pages={otherPages} />}</Box>
                </div>
              </>
            )}
          </div>
        )}
      </Flex>
    </HeaderStyle>
  );
};

export default withTheme(Header);
