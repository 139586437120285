import React, { useRef, useState, useEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const isDev = process.env.NODE_ENV === 'development';

export const trimSlashes = str => str.replace(/^\/|\/$/g, '');

export function resetBrowser(ref) {
  if (ref?.current) {
    setTimeout(() => {
      ref.current.scrollTo({ top: 0, behavior: 'smooth' });
    }, 100);
  }
  if (document) {
    let viewportmeta = document.querySelector('meta[name="viewport"]');
    if (viewportmeta === null) {
      viewportmeta = document.createElement('meta');
      viewportmeta.setAttribute('name', 'viewport');
      document.head.appendChild(viewportmeta);

      viewportmeta = document.querySelector('meta[name="viewport"]');
    }
    viewportmeta.setAttribute('content', 'initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0');
  }
}

// TODO: Not in use. Probably delete this. ⬇
export const setupSequence = sanityData => [
  ...sanityData.prePages,
  ...sanityData.questions,
  ...sanityData.postPages,
];

export const debugStyles = `* { outline: 1px solid rgba(255,0,0,.2); :hover { outline: 1px solid rgba(255,0,0,0.6); } }
* * { outline: 1px solid rgba(0,255,0,.2); :hover { outline: 1px solid rgba(0,255,0,0.6); } }
* * * { outline: 1px solid rgba(0,0,255,.2); :hover { outline: 1px solid rgba(0,0,255,0.6); } }
* * * * { outline: 1px solid rgba(255,0,255,.2); :hover { outline: 1px solid rgba(255,0,0,0.6); } }
* * * * * { outline: 1px solid rgba(0,255,255,.2); :hover { outline: 1px solid rgba(0,255,0,0.6); } }
* * * * * * { outline: 1px solid rgba(255,255,0,.2); :hover { outline: 1px solid rgba(0,0,255,0.6); } }
* * * * * * * { outline: 1px solid rgba(255,0,0,.2); :hover { outline: 1px solid rgba(255,0,0,0.6); } }
* * * * * * * * { outline: 1px solid rgba(0,255,0,.2); :hover { outline: 1px solid rgba(0,255,0,0.6); } }
* * * * * * * * * { outline: 1px solid rgba(0,0,255,.2); :hover { outline: 1px solid rgba(0,0,255,0.6); } }
`;

const initialState = { width: 0, height: 0 };
//  ref is the reference to the element whose height and with is required
//  const divRef = useRef(null);
//  const { height, width } = useDimension(divRef);
//  <div ref={divRef}>
export const useDimension = ref => {
  const [dimensions, setdDimensions] = useState(initialState);
  const resizeObserverRef = useRef(null);

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries = []) => {
      entries.forEach(entry => {
        const { width, height } = entry.contentRect;
        setdDimensions({ width, height });
      });
    });
    if (ref.current) resizeObserverRef.current.observe(ref.current);
    return () => {
      if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
    };
  }, [ref]);
  return dimensions;
};

// REVIEW: Do we need this? Prolly not.
export function useDoubleClick(callback) {
  const [elem, setElem] = React.useState(null);
  const countRef = React.useRef(0);
  const timerRef = React.useRef(null);
  const inputCallbackRef = React.useRef(null);
  const callbackRef = React.useCallback(node => {
    setElem(node);
    callbackRef.current = node;
  }, []);

  React.useEffect(() => {
    inputCallbackRef.current = callback;
  });

  React.useEffect(() => {
    function handler() {
      const isDoubleClick = countRef.current + 1 === 2;
      const timerIsPresent = timerRef.current;
      if (timerIsPresent && isDoubleClick) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
        countRef.current = 0;
        if (inputCallbackRef.current) {
          inputCallbackRef.current();
        }
      }
      if (!timerIsPresent) {
        // eslint-disable-next-line operator-assignment
        countRef.current = countRef.current + 1;
        const timer = setTimeout(() => {
          clearTimeout(timerRef.current);
          timerRef.current = null;
          countRef.current = 0;
        }, 200);
        timerRef.current = timer;
      }
    }
    if (elem) {
      elem.addEventListener('click', handler);
    }

    return () => {
      if (elem) {
        elem.removeEventListener('click', handler);
      }
    };
  }, [elem]);
  return [callbackRef, elem];
}

export const extractURLParams = string => {
  const params = new URLSearchParams(string);

  const obj = {};
  // iterate over all keys
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const getInitials = urlString => ({
  initials: {
    sessionStart: new Date().getTime(),
    agent: window.navigator.userAgent || 'Undetermined User Agent',
    referrer: document.referrer || 'No referrer',
    vw: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
    vh: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    params: extractURLParams(urlString),
  },
  cheated: false,
  lastAction: {
    type: 'startPage',
    index: 0,
  },
});
