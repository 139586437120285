import React from 'react';
import ReactPortableText, { blockContentToPlainText } from 'react-portable-text';
import styled from 'styled-components';
import { useLocation } from 'react-use';
import { array } from 'prop-types';
import { blockConfig } from '../lib/sanity';
import { useStateValue } from '../state';

const StyledBlock = styled(ReactPortableText)`
  .placeholder {
    font-weight: bold;
    color: ${p => p.theme?.sanity?.brand?.feedbackPageStyle?.numberColor || 'black'};
  }

  figure {
    top: 1rem;
    padding: 0;
    margin: -2rem -2rem 1rem -2rem;
    margin: 0;
    img {
      max-width: 100%;
    }
  }

  &.christmasStyle {
    background: #0c0f27;
    margin: -2em -2em -1em;
    padding: 2em;
    padding-bottom: 1px;
    color: white;
  }

  @media (max-width: 500px) {
    &.christmasStyle {
      margin: -2em -24px -1em;
      padding: 2em 24px;
      padding-bottom: 1px;
    }
  }
`;

const PortableText = ({ content, blockStartButton, isFirst }) => {
  const { search } = useLocation();

  const [{ itsChristmas }, dispatch] = useStateValue();

  // console.log({ blockStartButton });

  // If it's Christmas and the blockStartbutton is true apply Christmas styles

  const specialStyles = itsChristmas && blockStartButton;

  console.log({ specialStyles });

  const [
    {
      score,
      quiz: { questions },
    },
  ] = useStateValue();
  if (!content) return null;
  return (
    <StyledBlock
      className={specialStyles ? 'christmasStyle' : ''}
      {...blockConfig}
      imageOptions={{ w: 1024, fit: 'max' }}
      content={content}
      serializers={{
        // eslint-disable-next-line react/prop-types
        link: ({ children, href }) => {
          return (
            <a href={`${href}${search}`} target="_blank" rel="noreferrer">
              {children}
            </a>
          );
        },
        p: ({ children, ...props }) => {
          return (
            <p {...props} style={{ border: '0px solid red' }}>
              {children}
            </p>
          );
        },
        h1: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
        placeholder_score: () => {
          return <span className="placeholder score">{`${score || 0}`}</span>;
        },
        placeholder_total: () => {
          return <span className="placeholder total">{`${questions.length || 0}`}</span>;
        },
        li: ({ children }) => <li className="special-list-item">{children}</li>,
        // image: props => {
        //   // If the link exists and has length, wrap the image in a link.
        //   if (props.link && props.link.length > 1) {
        //     return (
        //       <a target="_blank" href={props.link}>
        //         <figure>
        //           <img alt="img" width="100%" src={props.asset.url} />
        //         </figure>
        //       </a>
        //     );
        //   }
        //   return (
        //     <figure>
        //       <img alt="img" width="100%" src={props.asset.url} />
        //     </figure>
        //   );
        // },
        portableTextImage: props => {
          if (props.link && props.link.length > 1) {
            return (
              <a href={`${props.link}${search}`} target="_blank">
                <figure>
                  <img alt="img" width="100%" src={props.asset.url} />
                </figure>
              </a>
            );
          }
          return (
            <figure>
              <img alt="img" width="100%" src={props.asset.url} />
            </figure>
          );
        },
      }}
    />
  );
};

export default PortableText;

PortableText.propTypes = {
  content: array.isRequired,
};

export const toPlainText = blocks => blockContentToPlainText(blocks);
